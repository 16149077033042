.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
}

.mt-5 {
  margin-top: 20px;
}

form {
    box-sizing: border-box;
    flex: 1 0 auto;
    display: flex;
    max-width: 500px;
    justify-content: center;
    min-width: 300px;
}

form input {
    outline: none;
    padding: 8px;
    border: 1px solid rgb(223, 223, 223);
    background-color: rgb(243, 243, 243);
    flex: 1 auto;
    border-radius: 10px;
    margin-right: 10px;
    min-height: 22px;
    display: flex;
    font-size: medium;
}

form input:focus {
    border: 1px solid black
}

.input-line {
  text-align: left;
  width: fit-content;
}

button {
  background-color: black;
  border-radius: 5px;
  outline: none;
  border: none;
  height: 40px;
  width: 50px;
}

.error-container {
  height: 40px;
  margin: 0;
  padding: 0;
}

.error-description {
  color: rgb(255, 78, 78);
  padding: 10px 5px;
  margin: 0;
  font-size: small;
}

h3 {
  padding-bottom: 0;
  margin-bottom: 0;
}

p {
  color: rgb(156, 156, 156);
  padding: 20px 50px;
  margin: 0;
}

section {
  background-color: hsl(60, 11%, 98%);
  border-top: 1px solid rgb(216, 214, 211);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30vh;
  width: 100%;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.height-150 {
  height: 150px;
}